import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { FullScreenImage, HeadlineCopy, HeadlineWrapper } from ".";
import Layout from "../components/layout";
import { Button, Form } from "semantic-ui-react";
import { ErrorMessage, Wrapper } from "../components/styles";
import { Spacer } from "ui-start";
import { HeroImage } from "../components/heroImage";

const Feedback = () => {
  const data = useStaticQuery(graphql`
    query feedbackPageQuery {
      allGoogleSheet {
        edges {
          node {
            feedback {
              title
              heroImage
            }
          }
        }
      }
    }
  `);

  const [inputs, setInputs] = useState({});
  const [nameError, setNameError] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);

  const pageData = data?.allGoogleSheet?.edges?.[0]?.node?.feedback?.[0];

  const handleInput = (e) => {
    const textTypes = ["text", "textarea"];
    if (textTypes.includes(e.target.type)) {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleRadioInput = (e, { value, name }) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setNameError("");
    if (inputs["name"]) {
      fetch(
        "https://sheetdb.io/api/v1/swxlr42wquz8p?sheet=volunteerfeedbackform",
        {
          method: "POST",
          body: JSON.stringify({
            data: [inputs],
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      setFormSuccess(true);
      setInputs({});
    } else {
      setNameError("* Name is required");
    }
  };

  return (
    <Layout>
     <HeroImage page='feedback' />
      <Wrapper>
        <Spacer height={32} />
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label htmlFor="feedback-name">Your Name*</label>
            <input
              id="feedback-name"
              placeholder="Name"
              name="name"
              type="text"
              onChange={handleInput}
              error={!!nameError}
            />
            <Spacer height={8} />
            <ErrorMessage>{nameError}</ErrorMessage>
          </Form.Field>
          <Form.Field>
            <label htmlFor="feedback-business">Business you represent</label>
            <input
              id="feedback-business"
              placeholder="Business"
              name="business"
              type="text"
              onChange={handleInput}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="feedback-date">
              On what date did you volunteer for ELSA Next Generation?
            </label>
            <input
              id="feedback-date"
              placeholder="Date"
              name="date"
              type="text"
              onChange={handleInput}
            />
          </Form.Field>
          <Spacer height={32} />
          <Form.Group inline>
            <label htmlFor="referral">
              How did you hear about ELSA Next Generation?
            </label>
            <Form.Radio
              name="referral"
              label="Website"
              value="website"
              checked={inputs?.referral === "website"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="Word of mouth"
              value="word of mouth"
              name="referral"
              checked={inputs?.referral === "word of mouth"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="Email"
              value="email"
              name="referral"
              checked={inputs?.referral === "email"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="LinkedIn"
              value="linkedin"
              name="referral"
              checked={inputs?.referral === "linkedin"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="Instagram"
              value="instagram"
              name="referral"
              checked={inputs?.referral === "instagram"}
              onChange={handleRadioInput}
            />
          </Form.Group>
          <Spacer height={32} />
          <Form.Group inline>
            <label htmlFor="initialComs">
              How would you describe the initial communication from ELSA Next
              Generation?
            </label>
            <Form.Radio
              label="Excellent"
              value="excellent"
              name="initialComs"
              checked={inputs?.initialComs === "excellent"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="Good"
              value="good"
              name="initialComs"
              checked={inputs?.initialComs === "good"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="Fair"
              value="fair"
              name="initialComs"
              checked={inputs?.initialComs === "fair"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="Poor"
              value="poor"
              name="initialComs"
              checked={inputs?.initialComs === "poor"}
              onChange={handleRadioInput}
            />
          </Form.Group>
          <Spacer height={32} />
          <Form.Group inline>
            <label htmlFor="madeToFeelWelcome">
              On arrival at the venue, were you made to feel welcome?
            </label>
            <Form.Radio
              label="Yes"
              value="yes"
              name="madeToFeelWelcome"
              checked={inputs?.madeToFeelWelcome === "yes"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="No"
              value="no"
              name="madeToFeelWelcome"
              checked={inputs?.madeToFeelWelcome === "no"}
              onChange={handleRadioInput}
            />
          </Form.Group>
          <Spacer height={32} />
          <Form.Group inline>
            <label htmlFor="wasRoleClearlyExplained">
              Did you feel that your role was clearly explained? Did you know
              what was expected of you?
            </label>
            <Form.Radio
              label="Yes"
              value="yes"
              name="wasRoleClearlyExplained"
              checked={inputs?.wasRoleClearlyExplained === "yes"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="No"
              value="no"
              name="wasRoleClearlyExplained"
              checked={inputs?.wasRoleClearlyExplained === "no"}
              onChange={handleRadioInput}
            />
          </Form.Group>
          <Spacer height={32} />
          <Form.Group inline>
            <label htmlFor="didYouFeelSupported">
              Did you feel supported by ELSA Next Generation at the event?
            </label>
            <Form.Radio
              label="Yes"
              value="yes"
              name="didYouFeelSupported"
              checked={inputs?.didYouFeelSupported === "yes"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="No"
              value="no"
              name="didYouFeelSupported"
              checked={inputs?.didYouFeelSupported === "no"}
              onChange={handleRadioInput}
            />
          </Form.Group>
          <Spacer height={32} />
          <Form.Group inline>
            <label htmlFor="rateOverallExperience">
              Overall how would you rate your experience with ELSA Next
              Generation?
            </label>
            <Form.Radio
              label="Excellent"
              value="excellent"
              name="rateOverallExperience"
              checked={inputs?.rateOverallExperience === "excellent"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="Good"
              value="good"
              name="rateOverallExperience"
              checked={inputs?.rateOverallExperience === "good"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="Fair"
              value="fair"
              name="rateOverallExperience"
              checked={inputs?.rateOverallExperience === "fair"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="Poor"
              value="poor"
              name="rateOverallExperience"
              checked={inputs?.rateOverallExperience === "poor"}
              onChange={handleRadioInput}
            />
          </Form.Group>
          <Spacer height={32} />
          <Form.Group inline>
            <label htmlFor="futureSupport">
              Would you be willing to support a future event with ELSA Next
              Generation?
            </label>
            <Form.Radio
              label="Yes"
              value="yes"
              name="futureSupport"
              checked={inputs?.futureSupport === "yes"}
              onChange={handleRadioInput}
            />
            <Form.Radio
              label="No"
              value="no"
              name="futureSupport"
              checked={inputs?.futureSupport === "no"}
              onChange={handleRadioInput}
            />
          </Form.Group>

          <Spacer height={32} />
          <Form.TextArea
            label="Any other comments"
            placeholder="Any other comments?"
            name="comments"
            onChange={handleInput}
          />
          <Spacer height={32} />
          <Button type="submit">Submit</Button>
          <Spacer height={12} />
          {formSuccess && <h2>Many thanks. Your form has been sent</h2>}
        </Form>
      </Wrapper>
    </Layout>
  );
};

export default Feedback;
